import RestClient from './RestClient'
import { getIsMobileOrTablet } from '../../utils/isMobileOrTablet'
import {
  DateOptionsRsvpDto,
  HypeDto,
  RsvpDtoV2,
  WaitListDto,
} from 'types/types'

const imageSizes = {
  eventPage: {
    imageSizesEvent: 'md,lg,original',
    imageSizesAvatar: 'sm,lg',
    imageSizesOrgAvatar: 'lg,xl',
    imageSizesMessages: 'md,lg',
  },
  eventListMin: {
    imageSizesEvent: 'lg',
    imageSizesAvatar: 'sm',
    imageSizesOrgAvatar: 'sm',
    imageSizesMessages: '',
  },
  eventListMinOrg: {
    imageSizesEvent: 'lg',
    imageSizesAvatar: 'sm',
    imageSizesOrgAvatar: 'sm',
    imageSizesMessages: '',
  },
  eventListDiscoverCard: {
    imageSizesEvent: 'cardSm, lg',
    imageSizesAvatar: 'xs,lg,sm',
    imageSizesOrgAvatar: 'lg',
    imageSizesMessages: '',
  },
  orgInfo: {
    imageSizesEvent: 'cardXs, lg',
    imageSizesAvatar: '',
    imageSizesOrgAvatar: 'xl,lg',
    imageSizesMessages: '',
  },
  search: {
    imageSizesEvent: 'lg',
    imageSizesAvatar: 'lg',
    imageSizesOrgAvatar: 'lg',
    imageSizesMessages: '',
  },
  comments: {
    imageSizesEvent: 'lg',
    imageSizesAvatar: 'lg',
    imageSizesOrgAvatar: 'lg',
    imageSizesMessages: '',
  },
  message: {
    imageSizesEvent: 'lg',
    imageSizesAvatar: 'sm',
    imageSizesOrgAvatar: 'sm',
    imageSizesMessages: 'md,lg',
  },
  userProfile: {
    imageSizesEvent: 'lg',
    imageSizesAvatar: 'sm,xl',
    imageSizesOrgAvatar: '',
    imageSizesMessages: '',
  },
  friends: {
    imageSizesEvent: 'lg',
    imageSizesAvatar: 'sm,lg',
    imageSizesOrgAvatar: '',
    imageSizesMessages: '',
  },
}

export class ApiClient extends RestClient {
  constructor(baseUrl, options = {}, isMobileOrTablet = false) {
    super(baseUrl, options, isMobileOrTablet)
  }

  tags = {
    searchTags: (query, page, size) =>
      this.GET('/tags/display/search', { query, page, size }),
  }

  post = {
    media: {
      add: (postId, body) => this.POST(`/posts/${postId}/media`, body),
      patchPositions: (postId, body) =>
        this.PATCH(`/posts/${postId}/media`, body),
      removeMedia: (postId, mediaId) =>
        this.DELETE(`/posts/${postId}/media/${mediaId}`),
    },
  }

  event = {
    media: {
      add: (eventId, body) => this.POST(`/events/${eventId}/media`, body),
      videoStatus: (videoId) => this.GET(`/videos/${videoId}/status`),
      patchPositions: (eventId, body) =>
        this.PATCH(`/events/${eventId}/media`, body),
      removeMedia: (eventId, mediaId) =>
        this.DELETE(`/events/${eventId}/media/${mediaId}`),
    },
    getStatusCounts: (eventId) => this.GET(`/events/${eventId}/status-counts`),
    getAllActivity: (eventId, eventCode, key, size, before) =>
      this.GET(`/events/${eventId}/activity/all`, {
        eventCode,
        key,
        size,
        before,
      }),
    getFriendActivity: (eventId, page, size) =>
      this.GET(`/events/${eventId}/activity`, { page, size }),
    likeActivityLog: (activityLogId, emoji) =>
      this.POST(`/activity-logs/${activityLogId}/like`, { emoji }),
    unlikeActivityLog: (activityLogId) =>
      this.DELETE(`/activity-logs/${activityLogId}/like`),
    viewEvent: (eventId, location) =>
      this.POST(`/events/${eventId}/viewed`, {}, { location }),
    viewEventAmpli: (eventId, location) =>
      this.POST(`/events/${eventId}/viewed/ampli`, {}, { location }),
    byCode: (eventCode, inviterId, guestId, guestCode, ticketCode) => {
      return this.GET('/code/events', {
        ...imageSizes.eventPage,
        eventCode,
        inviterId,
        guestId,
        guestCode,
        tc: ticketCode,
      })
    },
    likeChat: (chatId, emoji) =>
      this.POST(`/chats/messages/${chatId}/like`, { emoji }),
    unlikeChat: (chatId) => this.DELETE(`/chats/messages/${chatId}/like`),
    likePost: (postId, emoji) => this.POST(`/posts/${postId}/like`, { emoji }),
    unlikePost: (postId) => this.DELETE(`/posts/${postId}/like`),
    sendChat: (chatId, message) =>
      this.POST(`/chats/${chatId}/message`, message),
    sendPost: (post) => this.POST('/posts/new', post),
    patchPost: (postId, body) => this.PATCH(`/posts/${postId}`, body),
    getReadBy: (messageId, page, size) =>
      this.GET(`/chats/messages/${messageId}/seen`, { page, size }),
    getChats: (chatGroupId, eventCode, key, size, before = 'true', guestCode) =>
      this.GET(`/chats/${chatGroupId}`, {
        eventCode,
        key,
        size,
        before,
        guestCode,
      }),
    getChatUsers: (chatGroupId) =>
      this.GET(`/chats/${chatGroupId}/users`, { size: '10000' }),
    getChatDigest: (eventId, eventCode, key, size) =>
      this.GET(`/events/${eventId}/chat/digest`, { eventCode, key, size }),
    getFollowersSeen: (eventId, page, size) =>
      this.GET(`/events/${eventId}/viewed/followers`, { page, size }),
    getTicketStats: (eventId) =>
      this.GET(`/ticket-options/events/${eventId}/stats`),
    getQuestionResponses: (questionId, page, size) =>
      this.GET(`/event-forms/responses/questions/${questionId}`, {
        page,
        size,
      }),
    getQuestionResponseCounts: (questionId) =>
      this.GET(`/event-forms/responses/questions/${questionId}/counts`, {}),
    getEventPosts: (
      eventId,
      page = 1,
      size = 10,
      before = 'true',
      postCode,
    ) => {
      return this.GET(`/posts/event/${eventId}`, {
        page,
        size,
        before,
        postCode,
      })
    },
    delete: (eventId) => this.DELETE(`/events/${eventId}`),
    like: (eventId) => this.POST(`/events/${eventId}/like`),
    dislike: (eventId) => this.DELETE(`/events/${eventId}/like`),
    getImages: (chatId, key, size) =>
      this.GET(`/chats/${chatId}/images`, {
        ...imageSizes.eventPage,
        key,
        size,
        before: true,
      }),
    getGuestsWithEvents: (eventId, excludeUserId) =>
      this.GET(`/events/${eventId}/guestsWithEvents`, {
        ...imageSizes.eventPage,
        excludeUserId,
      }),
    requestJoin: (eventId, requestReason) =>
      this.POST(`/events/${eventId}/request`, {
        requestMessage: requestReason,
      }),
    getUpdated: (eventCode, guestId, inviterId) =>
      this.GET('/code/events/updated', { eventCode, guestId, inviterId }),
    getDefaultImages: () => this.GET('/events/themeImages'),
    getMine: () => this.GET('/events', { ...imageSizes.eventListMin }),
    getHome: () => this.GET('/events/home', { ...imageSizes.eventListMin }),
    getFuture: (page, size) =>
      this.GET('/events/future', { page, size, ...imageSizes.eventListMin }),
    getMinePast: (page, size) =>
      this.GET('/events/past', { ...imageSizes.eventListMin, page, size }),
    getTba: (page, size) =>
      this.GET('/events/tba', { ...imageSizes.eventListMin, page, size }),
    getToday: (page, size) =>
      this.GET('/events/today', { ...imageSizes.eventListMin, page, size }),
    getInvites: (page, size) =>
      this.GET('/events/invites', { ...imageSizes.eventListMin, page, size }),
    getOpen: (page, size) =>
      this.GET('/events/open', { ...imageSizes.eventListMin, page, size }),
    getActive: (page, size) =>
      this.GET('/events/active', { ...imageSizes.eventListMin, page, size }),
    getCalendar: (page, size) =>
      this.GET('/events/home/calendar', {
        ...imageSizes.eventListMin,
        page,
        size,
      }),
    get: (eventId, guestCode, ticketCode) =>
      this.GET(`/events/${eventId}`, {
        ...imageSizes.eventPage,
        guestCode,
        tc: ticketCode,
      }),
    getPublic: () =>
      this.GET('/events/public', { ...imageSizes.eventListDiscoverCard }),
    findByQuery: (query) =>
      this.GET('/events/find', { ...imageSizes.search, query }),
    getTags: () => this.GET('/events/tags'),
    getByTagName: (name, page, size) =>
      this.GET('/events/tags/byName', {
        ...imageSizes.eventListDiscoverCard,
        name,
        page,
        size,
      }),
    create: (event, scrapeBotId) =>
      this.POST('/events', event, { ...imageSizes.eventPage, scrapeBotId }),
    post: (text, eventId, idea, type, toUserIds, postId, fromOrganisationId) =>
      this.POST('/posts', {
        text,
        eventId,
        idea,
        type,
        toUserIds,
        postId,
        fromOrganisationId,
      }),
    deletePost: (postId) => this.DELETE(`/posts/${postId}`),
    edit: (event, eventId, notifyAttendees) =>
      this.PATCH(`/events/${eventId}`, event, {
        ...imageSizes.eventPage,
        notifyAttendees,
      }),
    getImageUploadUrl: (eventId, format) =>
      this.GET(`/events/${eventId}/imageUploadUrl`, { format, asJson: true }),
    getVideoUploadUrl: (eventId, format) =>
      this.GET(`/events/${eventId}/videoUploadUrl`, { format, asJson: true }),
    postMessage: (message, chatId) =>
      this.POST(`/chats/${chatId}/message`, message, { ...imageSizes.message }),
    getMessage: (messageId) =>
      this.GET(`/events/messages/${messageId}/message`, {
        ...imageSizes.message,
      }),
    getMessageImage: (messageId, messageImageId) =>
      this.GET(`/events/messages/${messageId}/images/${messageImageId}`, {
        ...imageSizes.message,
      }),
    getMessages: (eventCode, page, size, guestId) =>
      this.GET('/code/events/messages', { eventCode, page, size, guestId }),
    likeEmojiMessage: (messageId, emoji) =>
      this.POST(`/events/messages/${messageId}/emoji`, emoji, undefined, true),
    unlikeEmojiMessage: (messageId, emoji) =>
      this.POST(
        `/events/messages/${messageId}/emoji/delete`,
        emoji,
        undefined,
        true,
      ),
    inviteUser: (userIds, eventId, asOrganisationId) =>
      this.POST(
        `/rsvp/events/${eventId}/invite`,
        {
          userIds,
          asOrganisationId,
        },
        imageSizes.eventPage,
      ),
    inviteUserNew: (invitees, eventId, asOrganisationId) =>
      this.POST(
        `/rsvp/events/${eventId}/invite`,
        {
          invitees,
          asOrganisationId,
        },
        imageSizes.eventPage,
      ),
    inviteUsers: (users, eventId) =>
      this.POST(`/events/${eventId}/invite`, users, imageSizes.eventPage),
    getInviteSuggestions: (eventId, query, friendsOnly, followersOnly, size, page) =>
      this.GET(`/events/${eventId}/inviteSuggestions`, { query, size, page, friendsOnly, followersOnly }),
    getInviteSuggestionsNoEvent: (query, size, page) =>
      this.GET('/events/inviteSuggestions', { query, size, page }),
    pinDate: (eventId, dateOptionId) =>
      this.GET(`/events/${eventId}/pin/date/${dateOptionId}`),
    getGuests: (eventId, eventCode, options, guestCode) =>
      this.GET(`/events/${eventId}/guests`, {
        eventCode,
        guestCode,
        ...options,
        ...imageSizes.eventPage,
      }),
    findByName: (query, page, size) =>
      this.GET('/events/find', { ...imageSizes.search, query, page, size }),
    getComments: (messageId, page, size, eventCode) =>
      this.GET(`/code/events/messages/${messageId}`, {
        ...imageSizes.comments,
        page,
        size,
        eventCode,
      }),
    postComment: (messageId, message) =>
      this.POST(`/events/messages/${messageId}`, message, {
        ...imageSizes.comments,
      }),
    deleteMessage: (messageId) => this.DELETE(`/events/messages/${messageId}`),
    // postMessageByGuestCode: (eventCode, guestId, privateCode, message) => this.POST('/code/events/messages', message, { eventCode, guestId, privateCode })
    postMessageByGuestCode: (eventCode, guestId, privateCode, message) =>
      this.POST('/code/events/chat', message, {
        eventCode,
        guestId,
        privateCode,
      }),
    getHosted: (page, size, past) =>
      this.GET('/events/hosted', {
        page,
        size,
        ...imageSizes.eventListMin,
        past,
      }),
    getCalendarFiltered: (key, size, direction, options, query) => {
      const { hosted, joined, hyped, birthdays } = options
      return this.GET('/home/calendar/events/filter', {
        key,
        size,
        direction,
        hosted,
        joined,
        hyped,
        birthdays: false,
        query,
      })
    },
    getGuestResponses: (guestId) =>
      this.GET(`/event-forms/responses/guests/${guestId}`, {}),
    patchGuestResponses: (guestId, responses, eventCode) =>
      this.PATCH(`/event-forms/responses/guests/${guestId}`, responses, {
        eventCode,
      }),
    editQuestion: (question) =>
      this.PATCH(`/event-forms/questions/${question.id}`, question, {
        ...imageSizes.eventPage,
      }),
    addQuestion: (questions, joinOptionId) =>
      this.POST(
        '/event-forms/questions/',
        { joinOptionId, questions },
        { ...imageSizes.eventPage },
      ),
    removeQuestion: (questionId) =>
      this.DELETE(`/event-forms/questions/${questionId}`),
    updateGuestsQuestions: (eventId) =>
      this.POST(`/event-forms/questions/events/${eventId}/updated`),
    editTicketProduct: (ticketProduct) =>
      this.PATCH(`/ticket-products/${ticketProduct.id}`, ticketProduct, {
        ...imageSizes.eventPage,
      }),
    addTicketProduct: (ticketProducts, eventId) =>
      this.POST(
        '/ticket-products',
        { eventId, ticketProducts },
        { ...imageSizes.eventPage },
      ),
    removeTicketProduct: (ticketProductId) =>
      this.DELETE(`/ticket-products/${ticketProductId}`),
    editTicketOption: (ticketOption) =>
      this.PATCH(`/ticket-options/${ticketOption.id}`, ticketOption, {
        ...imageSizes.eventPage,
      }),
    addTicketOption: (ticketOptions, eventId) =>
      this.POST(
        '/ticket-options',
        { eventId, ticketOptions },
        { ...imageSizes.eventPage },
      ),
    removeTicketOption: (ticketOptionId) =>
      this.DELETE(`/ticket-options/${ticketOptionId}`),
    updateGuestsTicketProducts: (eventId) =>
      this.POST(`/ticket-products/events/${eventId}/updated`),
    getChat: (chatGroupId) =>
      this.GET(`/chats/${chatGroupId}/chatgroup`, { ...imageSizes.eventPage }),
    getHottest: () =>
      this.GET('/events/hottest', { ...imageSizes.eventListDiscoverCard }),
    getEventsScanner: (uniqueId) =>
      this.GET(`/perks/admin/code/${uniqueId}/events`, {
        ...imageSizes.eventListDiscoverCard,
      }),
    getGuestsScanner: (uniqueId, eventId) =>
      this.GET(`/perks/admin/code/${uniqueId}/events/${eventId}/guests`, {
        ...imageSizes.eventListDiscoverCard,
      }),
    addCohostToEvent: (eventId, organisationId) =>
      this.POST(
        `/admin/events/${eventId}/organisations/${organisationId}/cohost`,
      ),
    getAnalytics: (eventId, fromDate, toDate) =>
      this.GET(`/events/stats/${eventId}`, { fromDate, toDate }),

    getPosts: (eventId, eventCode, page, size, guestCode) =>
      this.GET(`/posts/event/${eventId}`, {
        page,
        size,
        eventCode,
        guestCode,
      }),
  }

  featured = {
    get: () => this.GET('/featured/home'),
  }

  search = {
    all: (query, page, size) =>
      this.GET('/search/all', { ...imageSizes.search, query, page, size }),
  }

  tickets = {
    getPaymentInfo: (ticketOrderId, guestCode) =>
      this.GET(`/tickets/orders/${ticketOrderId}/payment`, { guestCode }),
    getOrder: (ticketOrderId, guestCode) =>
      this.GET(`/tickets/orders/${ticketOrderId}`, { guestCode }),
    getByEvent: (eventId, guestCode) =>
      this.GET(`/events/${eventId}/tickets`, { guestCode }),
    cancelOrder: (ticketOrderId, guestCode) =>
      this.POST(`/tickets/orders/${ticketOrderId}/cancel`, { guestCode }),
    orderMoreTickets: (ticketOptions, guestCode) =>
      this.POST('/tickets/orders', { ticketOptions }, { guestCode }),
    revokeTicket: (ticketIds, guestCode) =>
      this.POST('/tickets/revoke', { ticketIds: ticketIds }, { gc: guestCode }),
  }

  rsvp = {
    normal: (
      eventId: number,
      dto: RsvpDtoV2,
      eventCode: string,
      location: string,
      guestCode?: string,
      inviterId?: number,
    ) =>
      this.POST(`/rsvp/events/${eventId}`, dto, {
        ...imageSizes.eventPage,
        eventCode,
        location,
        gc: guestCode,
        inviterId,
      }),
    waitlist: (
      eventId,
      dto: WaitListDto,
      eventCode,
      location,
      guestCode,
      inviterId,
    ) =>
      this.POST(`/rsvp/events/${eventId}/waiting-list`, dto, {
        ...imageSizes.eventPage,
        eventCode,
        location,
        gc: guestCode,
        inviterId,
      }),
    hype: (eventId, dto: HypeDto, eventCode, location, guestCode, inviterId) =>
      this.POST(`/rsvp/events/${eventId}/hype`, dto, {
        ...imageSizes.eventPage,
        eventCode,
        location,
        gc: guestCode,
        inviterId,
      }),
    dateOptions: (
      eventId: number,
      dto: DateOptionsRsvpDto,
      eventCode?: string,
      gc?: string,
      location?: string,
      inviterId?: number,
    ) =>
      this.POST(`/rsvp/events/${eventId}/date-options`, dto, {
        ...imageSizes.eventPage,
        eventCode,
        gc,
        location,
        inviterId,
      }),
    remove: (eventId: number, guestCode?: string) =>
      this.DELETE(`/rsvp/events/${eventId}`, { guestCode }),
  }

  ticket = {
    setupIntent: (guestCode, emailAddress, name) =>
      this.GET(`/tickets/setup-intent`, { guestCode, emailAddress, name }),
    checkStatus: (eventId, ticketOrderId, eventCode) =>
      this.GET(`/code/events/${eventId}/tickets/orders/${ticketOrderId}`, {
        eventCode,
      }),
    cancel: (eventId, ticketOrderId, eventCode) =>
      this.PATCH(
        `/code/events/${eventId}/tickets/orders/${ticketOrderId}/cancel`,
        null,
        { eventCode },
      ),
  }

  guest = {
    giveTickets: (guestId, ticketAmount) =>
      this.POST(`/guests/${guestId}/give-tickets`, {
        ticketAmount,
      }),
    approve: (guestId) => this.POST(`/rsvp/${guestId}/approve`),
    reject: (guestId) => this.POST(`/rsvp/${guestId}/reject`),
    patch: (guestId, body, gc) =>
      this.PATCH(`/guests/${guestId}`, body, { ...imageSizes.eventPage, gc }),
    finishProfile: (guestId, body, gc) =>
      this.POST(`/guests/${guestId}/finish-profile`, body, { ...imageSizes.eventPage, gc }),
    patchPerk: (guestId, body, organisationCode) =>
      this.PATCH(
        `/perks/admin/code/${organisationCode}/guests/${guestId}`,
        body,
        { ...imageSizes.eventPage },
      ),
    delete: (guestId) => this.DELETE(`/guests/${guestId}`),
    getByCode: (guestCode) => this.GET('/guests/code', { guestCode }),
    patchByEventCode: (eventCode, body) => {
      return this.PATCH('/code/events/guests', body, { eventCode })
    },
    addByEventCode: (eventCode, body) => {
      return this.POST('/code/events/guests', body, { eventCode })
    },
  }

  image = {
    checkReplaced: (imageId) => this.GET(`/images/${imageId}/replaced`),
  }

  organisation = {
    find: (username, fromIframe) =>
      this.GET('/organisations/find', {
        ...imageSizes.orgInfo,
        username,
        fromIframe,
      }),
    get: (orgId) => this.GET(`/organisations/${orgId}`, {}),
    getEvents: (orgId, page, size) =>
      this.GET(`/organisations/${orgId}/events`, {
        page,
        size,
        ...imageSizes.eventListMinOrg,
      }),
    getFollowers: (orgId, page, size) =>
      this.GET(`/organisations/${orgId}/followers`, {
        page,
        size,
        ...imageSizes.eventListMinOrg,
      }),
    getPastEvents: (id, page, size) =>
      this.GET(`/organisations/${id}/events/past`, {
        ...imageSizes.eventListMinOrg,
        page,
        size,
      }),
    follow: (id) =>
      this.GET(`/organisations/${id}/follow`, { ...imageSizes.orgInfo }),
    unfollow: (id) =>
      this.GET(`/organisations/${id}/unfollow`, { ...imageSizes.orgInfo }),
    findByName: (name, page, size, type) =>
      this.GET('/organisations/findByName', {
        ...imageSizes.search,
        name,
        page,
        size,
        type,
      }),
    getAllUsernames: () => this.GET('/organisations/all/usernames'),

    getBillingDetails: async (id) => {
      try {
        return await this.GET(`/organisations/${id}/billing/details`)
      } catch (e) {
        return null
      }
    },
    createBillingDetails: (id, billingDetails) =>
      this.POST(`/organisations/${id}/billing/details`, billingDetails),
    patchBillingDetails: (id, billingDetails) =>
      this.PATCH(`/organisations/${id}/billing/details`, billingDetails),

    makeFirstPayment: (id) =>
      this.POST(`/organisations/${id}/billing/mollie/first-payment`),
    getMandateStatus: (id) =>
      this.GET(`/organisations/${id}/billing/mollie/mandate/status`),

    getAnalytics: (organisationId, fromDate, toDate) =>
      this.GET(`/events/stats/organisation/${organisationId}`, {
        fromDate,
        toDate,
      }),
    getQouta: (id) => this.GET(`/rsvp/invite/quota/organisations/${id}`),
  }

  organisationAdmin = {
    patchStripeAccount: (organisationId, body) =>
      this.PATCH('/tickets/connect', body, { organisationId }),
    connectStripeAccount: (organisationId) =>
      this.POST('/tickets/connect', { organisationId }),
    getStripeAccount: (organisationId) =>
      this.GET('/tickets/connect', {
        organisationId: organisationId,
      }),
    getMe: (orgId) =>
      this.GET(`/organisationsAdmin/${orgId}`, { ...imageSizes.orgInfo }),
    getMyFollowers: (offset, orgId) =>
      this.GET(`/organisationsAdmin/${orgId}/followers`, { offset }),
    getFollowers: (orgId, page, size, state) =>
      this.GET(`/organisationsAdmin/${orgId}/followers`, {
        ...imageSizes.friends,
        page,
        size,
        state,
      }),
    respondFollowers: (orgId, followers) =>
      this.POST(`/organisationsAdmin/${orgId}/respondFollowers`, followers),
    patchMe: (body, orgId) => this.PATCH(`/organisationsAdmin/${orgId}`, body),
    getMyStats: (orgId) => this.GET(`/organisationsAdmin/${orgId}/stats`),
    getUploadUrl: (format, orgId) =>
      this.GET(`/organisationsAdmin/${orgId}/avatarUploadUrl`, { format }),
    getUploadUrlCover: (format, orgId) =>
      this.GET(`/organisationsAdmin/${orgId}/cover-image/upload`, { format }),
    usernameAvailable: (username) =>
      this.GET('/organisationsAdmin/usernameAvailable', { username }),
    createOrg: (organisation, skipAdmin) =>
      this.POST('/organisationsAdmin/create', organisation, { skipAdmin }),
    getTicketFees: (orgId) =>
      this.GET(`/organisationsAdmin/${orgId}/ticketFees`),
    authoriseMollie: (code, orgId) =>
      this.GET(`/organisationsAdmin/${orgId}/authoriseMollie?code=${code}`),
    getConnectedApps: (orgId) =>
      this.GET(`/organisationsAdmin/${orgId}/connectedApps`),
    getUsers: (orgId) => this.GET(`/organisationsAdmin/${orgId}/users`),
    inviteUsers: (orgId, users) =>
      this.POST(`/organisationsAdmin/${orgId}/invite`, users),
    acceptInvite: (email, token) =>
      this.GET('/organisationsAdmin/acceptInvite', { email, token }),
    acceptInviteNoToken: (orgUserId) =>
      this.POST(`/organisationsAdmin/users/${orgUserId}/accept`),
    rejectInvite: (orgUserId) =>
      this.DELETE(`/organisationsAdmin/users/${orgUserId}`),
    getOrganisations: () => this.GET('/organisationsAdmin/users'),
    getMyOrganisations: () => this.GET('/organisationsAdmin/organisations'),
    authorizeTag: (orgId, tagCodes) =>
      this.POST(`/organisationsAdmin/${orgId}/events/tags/authorize`, tagCodes),
    changeRole: (orgId, orgUserId, role) =>
      this.GET(`/organisationsAdmin/${orgId}/changeRole/${orgUserId}`, {
        role,
      }),
    deleteUser: (orgId, orgUserId) =>
      this.GET(`/organisationsAdmin/${orgId}/deleteUser/${orgUserId}`),
    event: {
      getMine: (orgId) => this.GET(`/organisationsAdmin/${orgId}/events`),
      getMinePast: (orgId) =>
        this.GET(`/organisationsAdmin/${orgId}/events/past`),
      getTags: (orgId) => this.GET(`/organisationsAdmin/${orgId}/events/tags`),
      saveNew: (event, orgId) =>
        this.POST(`/organisationsAdmin/${orgId}/events`, event),
      save: (event, orgId) =>
        this.PATCH(`/organisationsAdmin/${orgId}/events/${event.id}`, event),
      getUploadUrl: (eventId, format, orgId) =>
        this.GET(
          `/organisationsAdmin/${orgId}/events/${eventId}/imageUploadUrl`,
          { format },
        ),
      getVideoUploadUrl: (eventId, format, orgId) =>
        this.GET(
          `/organisationsAdmin/${orgId}/events/${eventId}/videoUploadUrl`,
          { format },
        ),
      get: (eventId, orgId) =>
        this.GET(`/organisationsAdmin/${orgId}/events/${eventId}`),
      delete: (eventId, orgId) =>
        this.DELETE(`/organisationsAdmin/${orgId}/events/${eventId}`),
      getReachedAndMissed: (eventId, eventUpdateId, orgId) =>
        this.GET(
          `/organisationsAdmin/${orgId}/events/${eventId}/eventUpdates/${eventUpdateId}/reachedAndMissed`,
        ),
      announceDate: (orgId, eventId, startDate, endDate) =>
        this.POST(
          `/organisationsAdmin/${orgId}/events/${eventId}/announceDate`,
          { startDate, endDate },
        ),
    },
  }

  friends = {
    getBirthdays: (page, size) =>
      this.GET('/friends/birthdays', { page, size, ...imageSizes.friends }),
    getRecentBirthdays: () =>
      this.GET('/friends/recentBirthdays', { ...imageSizes.friends }),
    congratulate: (friend) => this.POST('/friends/congratulate', friend),
  }

  user = {
    delete: (confirmation) => this.DELETE('/users', { confirmation }),
    getMe: () => this.GET('/users'),
    getMyProfile: () =>
      this.GET('/users/profile', { ...imageSizes.userProfile }),
    getUserFollowing: (userId, page, size) =>
      this.GET(`/users/${userId}/following`, {
        page,
        size,
        type: 'ORGANISATION',
      }),
    getUserFriends: (userId, page, size) =>
      this.GET(`/friendships/${userId}`, { page, size }),
    getCalendar: (userId, key, size, direction) =>
      this.GET(`/users/${userId}/events`, { key, size, direction }),
    getProfile: (userId) =>
      this.GET(`/users/${userId}/profile`, { ...imageSizes.userProfile }),
    getFriends: (page, size) =>
      this.GET('/friendships', { page, size, ...imageSizes.friends }),
    getNotifications: (page, size) =>
      this.GET('/notifications', { page, size }),
    getNotificationSettings: () => this.GET('/notifications/settings'),
    patchNotificationSettings: (settings) =>
      this.PATCH('/notifications/settings', settings),
    getNotificationSettingsToken: (userId, unsubscribeToken) =>
      this.GET('/notifications/settings/token', { userId, unsubscribeToken }),
    patchNotificationSettingsToken: (userId, unsubscribeToken, settings) =>
      this.PATCH('/notifications/settings/token', settings, {
        userId,
        unsubscribeToken,
      }),
    addFriend: (userId) =>
      this.POST(`/friendships/${userId}?location=web`, {
        ...imageSizes.friends,
      }),
    removeFriend: (userId) =>
      this.DELETE(`/friendships/${userId}`, { ...imageSizes.friends }),
    getUserByCode: (code) =>
      this.GET(`/users/code/${code}/profile`, { ...imageSizes.friends }),
    patchMe: (user) => this.PATCH('/users', user),
    signMeOut: () => this.GET('/users/signOut'),
    sendFeedback: (feedback) => this.POST('/feedback', feedback),
    getFriendRequests: () =>
      this.GET('/friendships/requests', { ...imageSizes.friends }),
    getFriendSuggestions: () =>
      this.GET('/friendSuggestions', { ...imageSizes.friends }),
    removeFriendSuggestion: (id) =>
      this.GET(`/friendSuggestions/${id}/dismiss`),
    verifyEmail: (email, token) =>
      this.GET('/users/verifyEmail', { email, token }),
    getUploadUrl: (format) =>
      this.GET('/users/avatarUploadUrlJSON', { format }),
    findByName: (name, page = 0, size = 10) =>
      this.GET('/users/findByName', { ...imageSizes.search, name, page, size }),
    findUsers: (name, page, size, excludeFriends) =>
      this.GET('/users/find', {
        ...imageSizes.search,
        name,
        excludeFriends,
        page,
        size,
      }),
    requestToken: (emailAddress, platform, name, followProfileId, redirectTo) =>
      this.POST(
        '/users/signIn/requestToken',
        { emailAddress, platform, name, followProfileId },
        { redirectTo },
      ),
    verifyToken: (emailAddress, token) =>
      this.POST('/users/signIn/verifyToken', { emailAddress, token }),
    requestCode: (emailAddress, redirectTo, respondingToEventId) =>
      this.POST(
        '/users/signIn/requestCode',
        { emailAddress, useWebLink: true, respondingToEventId },
        { redirectTo },
      ),
    verifyCode: (emailAddress, code, followProfileId) =>
      this.POST('/users/signIn/verifyCode', {
        emailAddress,
        code,
        followProfileId,
      }),
    verifyToken3rdParty: (token, type, timeZone, platform, followProfileId) =>
      this.POST(
        '/users',
        { timeZone },
        { token, type, platform, followProfileId },
      ),
    getCategories: () => this.GET('/categories'),
    getHypedEvents: (userId) =>
      this.GET(`/users/${userId}/hyped`, {
        ...imageSizes.eventListDiscoverCard,
      }),
    getQouta: () => this.GET(`/rsvp/invite/quota/users`),
  }

  general = {
    linkOpened: (utmSource, utmMedium, utmCampaign) =>
      this.GET('/analytics/linkOpened', { utmSource, utmMedium, utmCampaign }),
    analyticsEventEvent: (eventName, body) =>
      this.POST('/analytics/event', body, { eventName }),
    analyticsGeneralEvent: (eventName, body) =>
      this.POST('/analytics/general', body, { eventName }),
    analyticsUserEvent: (eventName, body) =>
      this.POST('/analytics/user', body, { eventName }),
    analyticsPageEvent: (eventName, body) =>
      this.POST('/analytics/page', body, { eventName }),
    analyticsTicketClick: (eventId, distinctId) =>
      this.GET(`/event-user-actions/events/${eventId}/ticket-link-clicked`, {
        location: 'web',
        distinctId,
      }),
    analyticsShareClick: (eventId, medium) =>
      this.GET(`/event-user-actions/events/${eventId}/share`, { medium }),
    getRegions: (withInactive) => this.GET('/regions', { withInactive }),
  }

  admin = {
    getAdmin: () => this.GET('/admin'),
  }

  newsletter = {
    unsubscribe: (recipientCode, type, newsletterEmailId) =>
      this.GET('/notifications/newsletter/unsubscribe', {
        recipientCode,
        type,
        newsletterEmailId,
      }),
    subscribe: (recipientCode, type, newsletterEmailId, emailAddress) =>
      this.GET('/notifications/newsletter/subscribe', {
        recipientCode,
        type,
        newsletterEmailId,
        emailAddress,
      }),
  }
}

const client = new ApiClient(
  process.env.NEXT_PUBLIC_API_HOST,
  {},
  getIsMobileOrTablet(),
)

export default client
